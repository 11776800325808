@use 'mixins' as *;

.grid {
  @include grid;

  &-wide {
    @include grid($wide: true);
  }
}

.content {
  @include content-width();
  &-wide {
    @include content-width($wide: true);
  }
}
